import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { RegionPickerProps } from '@/components/RegionPicker/RegionPicker.model'
import styles from '@/components/RegionPicker/regionPicker.module.scss'
import { getCookies, setCookie } from 'cookies-next'
import { setExpirationDays } from '@/utils/cookies/cookies'
import { useRouter } from 'next/router'
import axios from 'axios'

const CONTENT_BASE_PATH = process.env.CONTENT_BASE_PATH
const CONTENT_DOMAIN = process.env.CONTENT_DOMAIN
const SITE_BRAND = process.env.SITE_BRAND

const RegionPicker = ({
  options,
  menuIsOpen,
  classNamePrefix,
  handleMenuOpenCallback,
  handleMenuCloseCallback,
}: RegionPickerProps) => {
  const router = useRouter()
  const { locale, countryCode } = getCookies()
  const [selectedOption, setSelectedOption] = useState(countryCode || 'US')
  const [defaultValue, setDefaultValue] = useState([])

  const isElavon = SITE_BRAND === 'elavon'

  useEffect(() => {
    if (options?.length > 0) {
      const filterList = options?.map(
        (match: any) =>
          Object.keys(match.options).length &&
          match.options.filter((option: any) => option.value === selectedOption)
      )
      const defaultVal: any = [...filterList[0], ...filterList[1]]
      setDefaultValue(defaultVal)
    }
  }, [locale])

  const redirectToHomePage = async () => {
    router.push('/').then(() => {
      window.location.reload()
    })
  }

  const handleTypeSelect = async (e: any) => {
    const selectedCountryUpperCase = e.value
    setSelectedOption(selectedCountryUpperCase)

    const selectedCountry = selectedCountryUpperCase.toLowerCase()
    const customLocale = 'en-' + selectedCountry

    setCookie('locale', customLocale, {
      expires: setExpirationDays(90),
    })
    setCookie('countryCode', selectedCountryUpperCase, {
      expires: setExpirationDays(90),
    })

    if (isElavon && router.asPath.includes('/products')) {
      const routerSlugList = router.query.slug

      if (!routerSlugList || typeof routerSlugList === 'string') {
        await redirectToHomePage()
        return
      }

      const currentCountry = countryCode?.toLowerCase()
      const currentProduct =
        currentCountry === 'us' ? routerSlugList[0] : routerSlugList[1]
      const currentVersion =
        currentCountry === 'us' ? routerSlugList[1] : routerSlugList[2]
      const isOverviewPage =
        routerSlugList.length === (currentCountry === 'us' ? 2 : 3)

      let redirectToUrl = '/'

      if (currentCountry === 'us') {
        redirectToUrl = `/products/${selectedCountry}/${routerSlugList.join(
          '/'
        )}${isOverviewPage ? '/overview' : ''}`
      }

      if (currentCountry !== 'us' && selectedCountry == 'us') {
        redirectToUrl = `/products/${routerSlugList.slice(1).join('/')}${
          isOverviewPage ? '/overview' : ''
        }`
      }

      if (currentCountry !== 'us' && selectedCountry !== 'us') {
        redirectToUrl = `/products/${selectedCountry}/${routerSlugList
          .slice(1)
          .join('/')}${isOverviewPage ? '/overview' : ''}`
      }

      const sideNavEndpoint = `${CONTENT_DOMAIN}${CONTENT_BASE_PATH}${customLocale}/${SITE_BRAND}/products/${currentProduct}/${currentVersion}.sidenavdata.json`

      let sideNavResponse: any

      try {
        sideNavResponse = await axios.get(sideNavEndpoint, { proxy: false })
      } catch (err) {
        await redirectToHomePage()
        return
      }

      if (
        sideNavResponse &&
        sideNavResponse.data &&
        sideNavResponse.data.length
      ) {
        const sideNavData = sideNavResponse.data
        const pageFound = sideNavData.some(
          (item: any) => item.path === redirectToUrl
        )
        router
          .push(
            pageFound
              ? redirectToUrl
              : redirectToUrl.split('/').slice(0, -1).join('/')
          )
          .then(() => {
            window.location.reload()
          })
      }
    } else {
      await redirectToHomePage()
    }
  }

  const selectorStyles = {
    container: (base: any, state: any) => ({
      ...base,
      padding: 0,
      border: 'none',
      borderRadius: 0,
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      cursor: 'pointer',
      textAlign: 'left',
      border: 0,
      borderRadius: '0.25rem',
      boxShadow: 'none',
      outline: state.isFocused ? 'rgb(10, 65, 197) solid 2px !important' : 0,
      outlineOffset: state.isFocused ? '1px' : 0,
    }),
    option: (styles: any) => ({
      ...styles,
      cursor: 'pointer',
      textAlign: 'left',
      boxShadow: 'none',
    }),
  }

  return (
    <>
      {options?.length > 0 && (
        <div
          className={
            classNamePrefix
              ? styles.regionPickerMobileWrapper
              : styles.regionPickerWrapper
          }
        >
          <Select
            isSearchable={false}
            options={options}
            aria-label={'Select Country'}
            formatOptionLabel={(options: any) => (
              <div
                className={
                  menuIsOpen ? styles.countryOptionOpen : styles.countryOption
                }
              >
                <img
                  src={process.env.CONTENT_DOMAIN + options.image}
                  alt="country-image"
                  width="24"
                  height="16"
                />
                <span>{options.label}</span>
              </div>
            )}
            onChange={handleTypeSelect}
            onMenuOpen={handleMenuOpenCallback}
            onMenuClose={handleMenuCloseCallback}
            styles={selectorStyles}
            value={defaultValue}
            label="Single select"
            classNamePrefix={classNamePrefix || 'filter'}
            menuIsOpen={menuIsOpen || false}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
    </>
  )
}

export default RegionPicker
