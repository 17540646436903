import { useQuery, UseQueryResult } from '@tanstack/react-query'
import productListService from './product-list.service'

export const useProductListQuery = () => {
  
  const useProductVersionsListQuery = (): UseQueryResult<any, unknown> => {
    return useQuery({
      queryFn: () => productListService.fetchProductVersionsList(),
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false
    })
  }
  

  return {
    useProductVersionsListQuery
  }
}