import { signOut } from 'next-auth/react'
import router from 'next/router'
import { showWarningToast } from '@/utils/toast/warningToast'

export const getLastStepupDuration = (stepupInterval: number) => {
  const now = Date.now()
  let lastStepupTime: any = sessionStorage.getItem('lastStepupTime')
  if (lastStepupTime) {
    lastStepupTime = parseInt(lastStepupTime)
    const setupDuration = (now - lastStepupTime) / (1000 * 60)
    return setupDuration >= stepupInterval || lastStepupTime == 0 ? true : false
  } else return false
}

export const setLastStepupTime = (now: number = Date.now()) => {
  sessionStorage.setItem('lastStepupTime', now.toString())
}

export const getCurrentSession = () => {
  const currentSession = sessionStorage.getItem('currentSession')
  let sessionStatus
  if (currentSession) sessionStatus = true
  else sessionStatus = false
  return sessionStatus
}

export const logoutUser = async () => {
  showWarningToast('You need to verify your identity again to access this feature. Login to the Developer Portal to continue.')
  await signOut({ callbackUrl: '/login', redirect: true })
  router.push('/login').then()
}

export const enableChallengeWidget = (stepupInterval: number) => {
  const featureMFA: boolean = process.env.FEATURE_MFA !== 'false'
  return (
    featureMFA && getLastStepupDuration(stepupInterval) && getCurrentSession()
  )
}
