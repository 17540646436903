import axios, { AxiosResponse } from 'axios'

export const mfaJWTValidation = async (username: string, token: string, policyParam: string = 'login') => {
  const postBody = { policy: policyParam, token: token }
  return await axios
    .post(`/api/mfa-jwt-validation?uid=${username}&aid=devportal_web`, postBody)
    .then((res: AxiosResponse) => {
      return res.data && res.data.sub ? true : false
    })
    .catch((error: any) => {
      return false
    })
}

export const getDeveloperDetails = async (username: string) => {
  const postBody = { username: username }

  return await axios
    .post(`${process.env.NEXTAUTH_URL}/api/users/fetch-user-by-username`, postBody)
    .then((res: AxiosResponse) => {
      return res.data
    })
    .catch((apiError: any) => {
      console.log('Login error', apiError)
      const apiErrorResponse = apiError.response
      const loginErrorMsg: string =
        'We\'re unable to provide access to your account. Please click on <a href="/forgot_password">\'Forgot Password\'</a> to set a new password or use <a href="/contact">Contact Us</a> for further assistance.'
      return {
        data: null,
        status: apiErrorResponse.status,
        message:
          apiErrorResponse.status == 401 || apiErrorResponse.status == 404
            ? loginErrorMsg
            : 'Something went wrong',
      }
    })
}
