import { useRouter } from 'next/router'
import { KeyboardEvent } from 'react'

export const useSearchBarUtil = () => {
  const router = useRouter()

  const handleSearchKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const searchTerm: string = event.target.value

      if (searchTerm.trim() !== '' && searchTerm.length >= 3) {
        const productId =
          router.asPath.split('/')[1] === 'products'
            ? router.asPath.split('/')[2]
            : null

        let query: { searchTermQuery: string; productId?: string } = {
          searchTermQuery: event.target.value,
        }

        if (productId) {
          query.productId = productId
        }

        router.push(
          {
            pathname: '/search',
            query,
          },
          undefined,
          { shallow: true }
        )
      }
    }
  }

  return {
    handleSearchKeyDown,
  }
}
