import axios from 'axios'

export const downloadDocument = async (
    documentPath: string,
    title: string,
    locale: string
) => {

    const url: string = process.env.CONTENT_DOMAIN + process.env.CONTENT_BASE_PATH + locale + '/' + process.env.CONTENT_BRAND + '/';

    const endpoint = url + documentPath + '.cfdata.json'

    const headers = { 'content-type': 'application/json' }

    const response = await axios
        .get(endpoint, {
            headers,
            proxy: false,
        })

    let downloadLink = process.env.CONTENT_DOMAIN + response.data.fileInfo.apiSpecJSONPath

    // Get file type from the URL

    const fileType = downloadLink.split('.').pop()

    try {
        if ((fileType != undefined) && (fileType != '') && (downloadLink != '')) {
            if (response.data.metaData.fileType == 'download') {
                try {
                    if (fileType == 'json') {
                        const fetchData = await fetch(
                            downloadLink
                        );
                        const parsed = await fetchData.json();
                        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(parsed));
                        const link = document.createElement('a');
                        link.setAttribute("href", dataStr);
                        link.setAttribute("download", title + '.' + fileType);
                        link.click();
                    } else if (fileType == 'pdf') {
                        const res = axios.get(downloadLink,
                            {
                                responseType: 'arraybuffer',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/pdf'
                                }
                            })
                            .then((response) => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', title + '.' + fileType);
                                document.body.appendChild(link);
                                link.click();
                            })
                            .catch((error) => console.log(error));
                    }
                    else {
                        throw new Error('File type cannot be downloaded');
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }
        else {
            throw new Error('Something went wrong. Please try again later.');
        }
    }
    catch (e) {
        console.log(e)
    }
}