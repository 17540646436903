import axios, { AxiosResponse } from 'axios'

const fetchProductVersionsList = async () => {
    const headers = { 'content-type': 'application/json' }
    
    return await axios
    .get(`/api/product-versions-list`, {
      headers,
      proxy:false
    })
    .then((res: AxiosResponse<any>) => {
      const render = res.data
      if(render === null) {
        return null
        
      }
      return render
    })
    .catch(function (error: any) {
      return null
    })
}

const productListService = {
    fetchProductVersionsList,  
}
export default productListService

