import styles from './inputSearch.module.scss'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
} from 'react'
import { USBIconSearch } from '@usb-shield/react-icons'

const SEARCH_INPUT_LABEL = 'search input'

export const InputSearch = ({
  inputID,
  inputName,
  inputValue,
  placeholder,
  handleSearchChange,
  handleSearchFocus,
  handleSearchKeyDown,
  handleSearchKeyUp,
  inputTitle,
  customClass,
}: {
  inputID: string
  inputName: string
  inputValue?: string
  placeholder?: string
  handleSearchChange?: ChangeEventHandler<HTMLInputElement>
  handleSearchFocus?: FocusEventHandler<HTMLInputElement>
  handleSearchKeyDown?: KeyboardEventHandler<HTMLInputElement>
  handleSearchKeyUp?: KeyboardEventHandler<HTMLInputElement>
  inputTitle?: string
  customClass?: string
}) => {
  return (
    <div className={styles.searchContainer + ' ' + customClass}>
      <label className={styles._label} htmlFor={inputID} aria-label={SEARCH_INPUT_LABEL}>
        <USBIconSearch
          size="20"
          addClasses={styles.usbSearch_label_icon}
          colorVariant="grey80"
        />
      </label>
      <input
        type="search"
        className={styles.usbSearch_input}
        id={inputID}
        name={inputName}
        value={inputValue}
        placeholder={placeholder || 'Search'}
        onChange={handleSearchChange}
        onFocus={handleSearchFocus}
        onKeyDown={handleSearchKeyDown}
        onKeyUp={handleSearchKeyUp}
        title={inputTitle}
        autoComplete="off"
      />
    </div>
  )
}
