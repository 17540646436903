import toast from 'react-hot-toast'

const WarningIcon = () => <img src="/alert.png" alt="Close" />

export const showWarningToast = (msg: string) => {
  toast.remove()
  toast(msg, {
    className: 'warning-toast',
    duration: 5000,
    style: {
      background: '#C33C00',
      borderRadius: '8px',
      color: 'white',
    },
    icon: <WarningIcon />,
    iconTheme: {
      primary: 'white',
      secondary: '#C33C00',
    },
  })
}
