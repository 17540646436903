// Default grid options
const defaultGridOptions = {
  gridGap: 'normal',
  alignItems: 'start',
  columnCount: '16',
  justifyContent: 'stretch',
  display: 'grid',
  padding: 'normal',
  type: 'vw'
}

// Default column options
const defaultColLayoutOptions = {
  span: null,
  spans: {
    xlarge: 16,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: "flex",
  padding: "zero",
  align: "start",
  justify: "start",
};

export {
  defaultGridOptions,
  defaultColLayoutOptions,
};
