import { LoginPageData } from './login.model'

export const loginPageData: LoginPageData = {
  loginPage: {
    title: 'Log in',
    description: 'Log in to access your account.',
    form: {
      username: {
        label: 'Username',
      },
      rememberUsername: {
        label: 'Remember my username',
      },
      password: {
        label: 'Password',
      },
      login: {
        label: 'Log in',
      },
      forgotPassword: {
        label: 'Forgot password?',
        link: '/forgot_password',
        linkAsButton: false,
      },
      forgotUsername: {
        label: 'Forgot username?',
        link: '/forgot-username',
        linkAsButton: false,
      },
      signUp: {
        label: 'Not a registered user? Sign up now.',
        link: '/sign-up',
        linkAsButton: false,
      },
    },
    successMsg: 'You have successfully logged in.',
    errorMsgs: {
      generalErrorMsg:
        "We're unable to provide access to your account. Please contact your main admin or apionboarding@usbank.com",
      somethingWentWrong: 'Please try again later.',
      softLockErrorMsg:
        'Too many failed login attempts in the configured time (2 minutes).',
      username: 'Username is required.',
      password: 'Password is required.',
    },
  },
}
