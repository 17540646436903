const twoColsOption = {
    span: null,
    spans: {
        large: 2,
        medium: 0,
        small: 0
    },
    offset: {
        large: 0,
        medium: 0,
        small: 0
    },
    display: 'flex',
    padding: 'zero',
    align: 'top',
    justify: 'left'
}

const sixColsOption = {
    span: null,
    spans: {
        large: 6,
        medium: 8,
        small: 4
    },
    offset: {
        large: 0,
        medium: 0,
        small: 0
    },
    display: 'block',
    padding: 'zero',
    align: 'top',
    justify: 'left'
}

const fourColsCardOption = {
    span: null,
    spans: {
        large: 4,
        medium: 8,
        small: 4
    },
    offset: {
        large: 0,
        medium: 0,
        small: 0
    },
    display: 'block',
    padding: 'zero',
    align: 'top',
    justify: 'left'
}

const twelveColsOption = {
    span: null,
    spans: {
        xlarge: 12,
        large: 12,
        medium: 8,
        small: 4
    },
    offset: {
        xlarge: 2,
        large: 0,
        medium: 0,
        small: 0
    },
    display: 'block',
    padding: 'zero',
    align: 'top',
    justify: 'left'
}

const twelveColsLoadingSpinnerOption = {
  ...twelveColsOption,
  align: 'center',
  justify: 'center',
}

const eightColsOption = {
    span: null,
    spans: {
        xlarge: 8,
        large: 8,
        medium: 6,
        small: 4
    },
    offset: {
        xlarge: 2,
        large: 2,
        medium: 0,
        small: 0
    },
    display: 'flex',
    padding: 'zero',
    align: 'start',
    justify: 'start'
};

export { twoColsOption, sixColsOption, fourColsCardOption, twelveColsOption, eightColsOption, twelveColsLoadingSpinnerOption } 