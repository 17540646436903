import ReactLoading from 'react-loading';

import styles from './ReactLoaders.module.scss';

const ReactLoadingInline = () => {
  return (
    <>
      <ReactLoading
        type={'spin'}
        color={'rgb(12 32 116)'}
        height={16}
        width={16}
        className={styles.inline}
      /> 
    </>
  )
}

export default ReactLoadingInline;
